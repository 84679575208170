* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  transition: 0.5s all;
}
body {
  font-family: 'Archivo', sans-serif;
  background-color: #011a1e;
  color: #d9e5e7;
}

h1,
h2,
h3,
h4 {
  color: whitesmoke;
}

.header-main {
  min-height: 100vh;
  background-color: #011a1e;
  padding: 50px;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #0f4550;
}

.top .social-media i {
  margin: 0 10px;
  color: white;
  font-size: 25px;
  border: 1px solid rgba(255, 69, 0, 0.22);
  padding: 2px;
}

.top .social-media i:hover {
  color: #0f4550;
}

.header-main .logo span {
  color: orangered;
  font-family: 'Bungee Spice', cursive;
}

.hero-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 100px;
}

.hero-box .hero-text {
  width: 60%;
}

.hero-box .hero-text p {
  font-size: 16px;
  color: #d3d1d1;
  line-height: 25px;
  font-weight: bold;
}

.hero-box .hero-text h2 {
  font-size: 35px;
  margin: 20px 0;
}

.hero-box .hero-text h3 {
  font-size: 25px;
  margin-bottom: 20px;
}

.hero-text .name {
  font-size: 30px;
  color: whitesmoke;
  text-decoration: underline;
  text-decoration-color: orangered;
  font-family: 'Bungee Spice', cursive;
}

.hero-text a {
  color: #d3d1d1;
  font-weight: bold;
}

.hero-text .button-box {
  margin: 40px 0;
  display: flex;
  align-items: center;
}

.hero-text .contact-opt-button {
  text-decoration: none;
  font-weight: normal;
  color: whitesmoke;
  padding: 10px;
  border-radius: 10px;
  background-color: #0f4550;
  margin: 0 5px;
}

.hero-text .contact-opt-button img {
  width: 50px;
  height: 50px;
}

.hero-text span {
  color: orangered;
}

.header-main .img-orbital-container .hero-img {
  position: relative;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 2px solid orangered;
  /* overflow: hidden; */
}

.header-main .img-orbital-container .hero-img .central-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
}

.header-main .img-orbital-container .hero-img .central-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-main .img-orbital-container .hero-img .orbit {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  animation: rotateCircle 10s linear infinite;
}

@keyframes rotateCircle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-main .img-orbital-container .hero-img .orbit img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes orbitAnimation {
  from {
    transform: rotate(0deg) translateX(100px) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateX(100px) rotate(-360deg);
  }
}

/*Les Sections : Section Skills*/
.skill-boxes {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-row-gap: 30px;
  grid-column-gap: 15px;
}

.skill-boxes .item {
  margin-top: 15px;
  background-color: #0f4550;
  padding: 10px;
  border-radius: 10px;
}

.access {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.access i {
  color: white;
  font-size: 24px;
}

.access a {
  text-decoration: none;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #011a1e;
}

.access a:hover {
  background-color: orangered;
}

.project-boxes {
  min-height: 500px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
}

.project-boxes .item {
  height: 400px;
  padding: 10px;
  background-color: #0f4550;
  border-radius: 7px;
  position: relative;
  margin: 15px 15px;
  overflow: hidden;
}

.project-boxes .item-body {
  height: 100%;
  width: 100%;
  top: 0px;
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: #011a1e32;
  backdrop-filter: blur(5px);
  border-radius: 15px;
  color: white;
  padding: 30px 20px;
  /* animation */
  right: -100%;
  transition: 2s;
}

.project-boxes .item:hover .item-body {
  right: 0;
}

.project-boxes .item .img-box {
  width: 100%;
  height: 280px;
}

.project-boxes .item .img-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-boxes .item p {
  font-size: 15px;
}

.project-boxes .item .tech-img {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #011a1e;
  padding: 7px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.project-boxes .item .tech-img img {
  height: 20px;
  width: 20px;
  margin: 0 15px;
}

/* end */

.section-intro {
  margin-bottom: 50px;
  margin-top: 30px;
}

.section-intro h5,
.item-body h5 {
  font-weight: bold;
}

.section-intro,
.item-body h5 {
  border-bottom: 1px solid orangered;
}

.section-intro i {
  font-size: 30px;
  margin-right: 15px;
}

.skill-logo img {
  width: 50px;
  height: 52px;
}

.skill-logo {
  padding: 15px;
  border: 2px solid orangered;
  width: max-content;
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
  top: -35px;
}

.skill-boxes h4 {
  color: #d3d1d1;
}

.tech {
  font-weight: bold;
  color: orangered;
}

/* Skills section */
.skill-section .my-skills {
  width: 100%;
  display: flex;
  align-items: center;
}

.skill-section .my-skills .skill-container .skill-container-item {
  margin-bottom: 15px;
}

.skill-section .my-skills .skill-container .skill-container-item h3 {
  margin-top: 15px;
}

.skill-section .my-skills .skill-container .skill-image-container {
  /* display: grid;
  grid-template-columns: repeat(3, 50px);
  grid-template-rows: repeat(5, 50px);
  gap: 15px;
  width: 50%; */
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.skill-section .my-skills .skill-container .skill-image {
  width: 70px;
  height: 70px;
  background-color: #0f4550;
  padding: 9px;
  cursor: pointer;
  margin: 8px;
  border-radius: 7px;
}

.skill-section .my-skills .skill-container .skill-image:hover {
  background-color: #011a1e96;
}

.skill-section .my-skills .skill-container .skill-image img {
  width: 100%;
  height: 100%;
}

.skill-section .my-skills .right-side {
  width: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

@media screen and (max-width: 565px) {
  .skill-section .my-skills {
    flex-direction: column;
  }

  .skill-section .my-skills .left-side {
    width: 100%;
    border-right: none;
    border-bottom: 4px dotted orangered;
    margin-bottom: 10px;
  }

  .skill-section .my-skills .right-side {
    width: 100%;
  }
}

.footer {
  background-color: #011a1e;
  margin-top: 50px;
  min-height: 200px;
  width: 100%;
  padding: 30px 100px;
}

.footer-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-box .left {
  width: 40%;
}

.footer-box .left i {
  color: whitesmoke;
  margin: 0 10px;
  font-size: 25px;
}
.footer-box .left i:hover {
  color: #d3d1d1;
}

.footer-box .left p {
  color: whitesmoke;
}

.footer-box .right .footer-img {
  border-radius: 50%;
  border: 3px solid orangered;
  width: max-content;
}

.footer-box .right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-box .right img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.footer .footer-box .right .name {
  font-size: 20px;
  color: whitesmoke;
  text-decoration: underline;
  text-decoration-color: orangered;
  font-family: 'Bungee Spice', cursive;
  text-align: left;
}

.bottom {
  width: 100%;
  padding: 15px;
}

.views {
  font-size: 14px;
  color: whitesmoke;
  text-align: left;
}

@media only screen and (max-width: 1115px) {
  .hero-box {
    top: 80px;
  }

  .hero-box .hero-text p {
    font-size: inherit;
  }
}

@media only screen and (max-width: 1040px) {
  .hero-box {
    top: 70px;
  }

  .hero-box .hero-text p {
    font-size: 14px;
  }

  .hero-box .hero-text h2 {
    font-size: 25px;
  }

  .hero-box .hero-text h3 {
    font-size: 18px;
  }

  .hero-img .main-profile-img {
    width: 250px;
    height: 250px;
  }
}

@media only screen and (max-width: 760px) {
  .hero-box {
    top: 60px;
  }

  .hero-box .hero-text p {
    font-size: 16px;
  }

  .hero-box .hero-text h2 {
    font-size: 18px;
  }

  .hero-box .hero-text h3 {
    font-size: 15px;
  }

  .hero-img .main-profile-img {
    width: 200px;
    height: 200px;
  }

  .footer-box {
    display: flex;
    flex-direction: column;
  }

  .footer-box .left {
    width: 100%;
  }
}

@media only screen and (max-width: 710px) {
  /*.header-main {*/
  /*  height: 150vh;*/
  /*}*/

  .hero-box {
    display: flex;
    flex-direction: column-reverse;
    top: 20px;
  }
  .hero-box .hero-text {
    width: 100%;
  }

  .hero-text .button-box {
    margin: 25px 0;
  }

  .logo {
    font-size: 13px;
  }

  .top .social-media i {
    margin: 0 5px;
    color: white;
    font-size: 13px;
    border: 1px solid rgba(255, 69, 0, 0.22);
    padding: 1px;
  }

  .hero-text .name {
    font-size: 16px;
    color: whitesmoke;
    text-decoration: underline;
    text-decoration-color: orangered;
    font-family: 'Bungee Spice', cursive;
  }

  .hero-img .main-profile-img {
    width: 250px;
    height: 250px;
  }

  .img-box .video {
    width: 100%;
  }
}

/* Adding somme css for tab name styling */
.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab {
  padding: 3px 15px;
  background-color: #0f4550;
  color: white;
  margin: 0 15px 15px 15px;
  border-radius: 10px;
  cursor: pointer;
}

body::-webkit-scrollbar {
  width: 7px;
  border-radius: 10px;
}

body::-webkit-scrollbar-track {
  background-color: #0f4550;
}

body::-webkit-scrollbar-thumb {
  background-color: #888;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
